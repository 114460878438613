import React from 'react';
import { Wrapper } from './couponWireframe.style';
import { ic_close } from 'react-icons-kit/md/ic_close';

import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';

import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Icon from 'react-icons-kit';

import moment from 'moment';

import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import { useIntl } from 'react-intl';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';
import { CardIOS, CardAndroid } from 'react-wallets';

type WireframeProps = {
  backgroundColor: any;
  promoCode: any;
  strip: any;
  expires: any;
  promotion: any;
  description: any;
  name: string;
  symbol: string;
  logo?: any;
  barcodeType: string;
};

const CouponWireframeAndroid: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    promoCode,
    strip,
    expires,
    promotion,
    description,
    name,
    symbol,
    logo,
    barcodeType,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const intl = useIntl();
  const [showDetails, setShowDetails] = React.useState(false);
  const [height, setHeight] = React.useState<string>('800px');
  const handleChange = () => {
    setShowDetails(!showDetails);
    height == '100%' ? setHeight('100%') : setHeight('100%');
  };

  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  return (
    <Wrapper
      backgroundColor={'#ffffff'}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      height={height}
    >
      <CardAndroid
        backgroundColor={backgroundColor.hex || ''}
        //card type----------
        type='coupon'
        //styles-------------

        //header-------------
        headerLogoLink={
          logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`
        }
        title={currentWallet.name}
        mainTitle={name || '____'}
        //banner---------------
        bannerImage={
          !strip
            ? cover
            : typeof strip === 'string'
            ? strip
            : URL.createObjectURL(strip)
        }
        walletImage={
          !strip
            ? cover
            : typeof strip === 'string'
            ? strip
            : URL.createObjectURL(strip)
        }
        expires={moment(expires).format('DD/MM/YYYY')}
        //information----------
        bottomLeftTitle='value'
        value={promotion ? promotion + ' ' + symbol : '___'}
        bottomLeftValue={promotion + ' ' + symbol}
        bottomRightTitle='expiration date'
        bottomRightValue={moment(expires).format('DD/MM/YYYY')}
        //access---------------
        // @ts-ignore
        accessBorder={false}
        // @ts-ignore
        accessType={barcodeType !== 'NONE' ? barcodeType : ''}
        accessCodeId={(barcodeType !== 'NONE' && promoCode) || ''}
        //details--------------
        descriptionTitle='description'
        description={description || '____'}
        promoCodeTitle='promo code'
        promoCode={promoCode}
        couponIdTitle='coupon id'
        couponId='625e9cc9197e60007963fd7'
        disableNotifications={'Désactiver Notifications'}
      />
    </Wrapper>
  );
};

export default CouponWireframeAndroid;
