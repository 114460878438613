import React from 'react';
import { Wrapper } from './couponWireframe.style';

import { ellipses } from 'react-icons-kit/iconic/ellipses';
import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';

import cover from '../../assets/images/lg-thumbnail.png';
import { getDataFromCache } from '../../utils/cache';
import { URL_IMAGE } from '../../apis/config';
import Icon from 'react-icons-kit';

import moment from 'moment';
import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';
import { CardIOS, CardAndroid } from 'react-wallets';

type WireframeProps = {
  backgroundColor: any;
  promotion: any;
  image: any;
  promoCode: any;
  description: any;
  titleColor: string;
  textColor: string;
  name: string;
  symbol: string;
  logo?: any;
  expirationDate: any;
  barcodeType: string;
};

const CouponWireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    image,
    promotion,
    promoCode,
    description,
    titleColor,
    textColor,
    name,
    symbol,
    expirationDate,
    logo,
    barcodeType,
  } = props;
  const currentWallet = getDataFromCache('wallet');
  const [showDetails, setShowDetails] = React.useState(true);

  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  const backFlip = () => {
    setShowDetails(!showDetails);
  };
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={titleColor}
      textColor={textColor}
    >
      <CardIOS
        backgroundColor={backgroundColor.hex || ''}
        //card type----------
        type='coupon'
        //styles-------------
        titleColor={titleColor}
        textColor={textColor}
        //header-------------
        headerLogoLink={
          logo ? logo : `${URL_IMAGE}/${currentWallet.logoPictureUrl}`
        }
        headerTitle={name || '___'}
        title='Alan'
        mainTitle='Jason'
        //banner---------------
        bannerImage={
          !image
            ? cover
            : typeof image === 'string'
            ? image
            : URL.createObjectURL(image)
        }
        //information----------
        bottomLeftTitle='value'
        bottomLeftValue={promotion ? promotion + ' ' + symbol : '___'}
        bottomRightTitle='expiration date'
        bottomRightValue={expirationDate}
        //access---------------
        // @ts-ignore

        accessType={barcodeType}
        accessCodeId='6066e4315abbcc1f9526d392'
        //details--------------
        descriptionTitle='description'
        description={description || '___'}
        promoCodeTitle='promo code'
        promoCode={promoCode || '___'}
        couponIdTitle='coupon id'
        couponId='625e9cc9197e60007963fd7'
      />
    </Wrapper>
  );
};

export default CouponWireframeIPhone;
