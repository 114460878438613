import 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
//Style
import styled from 'styled-components';
import { couponStyles } from './coupon.styles';
//Material
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
//Components
import InputLabel from '../../commons/Inputs/InputLabel';
//Commomns
import ColorPicker from '../../commons/ColorPicker';

import Button from '../../commons/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '../../commons/Inputs/Input';
import Select from '@material-ui/core/Select';
//Actions
import {
  couponPropsChanged,
  addCoupon,
  couponInitialState,
} from '../../actions';
//Reducers
import { RootState } from '../../reducers';
//Type
import { CouponFormProps, CouponStateError } from './coupon.types';

import cover from '../../assets/images/lg-thumbnail.png';

import InputFile from '../../commons/Inputs/InputFile';
import { isNumber, getNatural, getDecimal } from '../../utils/validations';

import { CircularProgress } from '@material-ui/core';
import { Currency } from '../../apis/ApiTypes';
import CouponWireframeIPhone from '../../components/CouponWireframeIPhone';
import CouponWireframeAndroid from '../../components/CouponWireframeAndroid';
import maxChars from '../../utils/fieldsMaxLength';
import BarcodeTypeSelection from '../../components/barcodeSelection';

const Coupon: React.FC<CouponFormProps> = (props) => {
  const intl = useIntl();
  const {
    walletId,
    expirationDate,
    labelColor,
    backgroundColor,
    foregroundColor,
    percentReduction,
    promoCode,
    image,
    source,
    description,
    loading,
    currencies,
    symbol,
    name,
    barcodeType,
    expirationDateError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    percentReductionError,
    promoCodeError,
    nameError,
    imageError,
    descriptionError,
    onSaveChanged,
  } = props;

  React.useEffect(() => {
    props.couponInitialState();
  }, []);

  const handleColorChange = (color: any, prop: string) => {
    props.couponPropsChanged(prop, color);
  };

  const [switchPreview, setSwitchPreview] = React.useState<string>('android');

  const handleFileChange = (event: any, prop: string) => {
    const file = event.target.files[0];
    if (['image/jpeg', 'image/png'].includes(file.type)) {
      props.couponPropsChanged(prop, file);
      return;
    }
    props.couponPropsChanged(
      'imageError',
      `Le type d'image n'est pas supporté`
    );
  };

  const handleDateChange = (prop: string) => (date: Date | null) => {
    props.couponPropsChanged(prop, date);
  };

  const areTextAreasValid = (fieldName: string, value: string): boolean => {
    if (value.length > (maxChars.coupon[fieldName] || maxChars[fieldName])) {
      props.couponPropsChanged(
        `${fieldName}Error`,
        'La longueur maximale a été atteinte'
      );
      return false;
    }
    return true;
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name: any = event.target.name;
    if (value == '') {
      props.couponPropsChanged(name, value);
    }
    props.couponPropsChanged('promoCodeError', '');
    props.couponPropsChanged('percentReductionError', '');

    if (name === 'percentReduction' && !isNumber(Number.parseInt(value))) {
      return;
    }

    if (name === 'percentReduction') {
      if (symbol == '%') {
        if (value.indexOf('.') != -1 || value.indexOf(',') != -1) {
          props.couponPropsChanged(
            'percentReductionError',
            'Seuls les chiffres sont autorisés'
          );
          return;
        }
        if (Number.parseInt(value) > 100) {
          props.couponPropsChanged(
            'percentReductionError',
            '100% est le pourcentage maximum autorisé'
          );
          return;
        }
      } else {
        if (getNatural(value).toString().length > 4) {
          props.couponPropsChanged(
            'percentReductionError',
            'Vous avez dépassé le nombre maximum avant le point/virgule'
          );
          return;
        }

        if (getDecimal(value).toString().length > 2) {
          props.couponPropsChanged(
            'percentReductionError',
            'Vous avez dépassé le nombre maximum aprés le point/virgule'
          );
          return;
        }
      }
    }

    if (name == 'promoCode') {
      if (value.length > maxChars.coupon.promocode) {
        props.couponPropsChanged(
          'promoCodeError',
          `Promo code ne peut pas comporter plus de ${maxChars.coupon.promocode} caractères`
        );
        return;
      }
    }
    const fieldName = event.target.name;

    if (!areTextAreasValid(fieldName, value)) return;

    props.couponPropsChanged(name, value);
  };

  const handleClickAddCoupon = () => {
    props.addCoupon(walletId, props, () => onSaveChanged());
  };

  const getFormatedMessage = (prop: CouponStateError) => {
    return props[prop] !== ''
      ? intl.formatMessage({ id: props[prop] })
      : props[prop];
  };

  const handleSelectChangePercent = (event: any) => {
    props.couponPropsChanged('symbol', event.target.value);
  };

  const handleSelectChange = (event: any) => {
    props.couponPropsChanged('source', event.target.value);
  };

  const handlePreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchPreview((event.target as HTMLInputElement).value);
  };

  return (
    <Wrapper>
      <div className='header'>
        <span className='title'>
          {intl.formatMessage({ id: 'coupon.label.addCoupon' })}
        </span>
        <Button onClick={handleClickAddCoupon}>
          {loading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            intl.formatMessage({ id: 'coupon.button.add' })
          )}
        </Button>
      </div>
      <div className='div-container' style={{ marginLeft: '15px' }}>
        <div className='div-content'>
          <div>
            <InputFile
              className='input-label'
              width={270}
              label={intl.formatMessage({ id: 'coupon.label.image' })}
              image={image}
              defaultImage={cover}
              error={getFormatedMessage('imageError')}
              onChange={(e) => {
                handleFileChange(e, 'image');
              }}
            ></InputFile>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '26px',
            }}
          >
            <ColorPicker
              label={intl.formatMessage({ id: 'coupon.label.textColor' })}
              color={labelColor}
              error={getFormatedMessage('labelColorError')}
              onChange={(color: any) => {
                handleColorChange(color, 'labelColor');
              }}
            />
            <ColorPicker
              label={intl.formatMessage({ id: 'coupon.label.foregroundColor' })}
              color={foregroundColor}
              error={getFormatedMessage('foregroundColorError')}
              onChange={(color: any) => {
                handleColorChange(color, 'foregroundColor');
              }}
            />
            <ColorPicker
              label={intl.formatMessage({ id: 'coupon.label.backgroundColor' })}
              color={backgroundColor}
              error={getFormatedMessage('backgroundColorError')}
              onChange={(color: any) => {
                handleColorChange(color, 'backgroundColor');
              }}
            />
          </div>

          <BarcodeTypeSelection
            setSelectedBarcode={(barcodeType) => {
              props.couponPropsChanged('barcodeType', barcodeType);
            }}
            barcodeType={barcodeType}
          ></BarcodeTypeSelection>
          <InputLabel
            label={intl.formatMessage({ id: 'coupon.label.name' })}
            name='name'
            value={name}
            error={getFormatedMessage('nameError')}
            placeholder='Nom'
            limit={maxChars.coupon.name || maxChars.name}
            onChange={(e) => handleEventChange(e)}
          />
          <div className='select'>
            <p>Source</p>
            <Select
              // open={selectOpen}
              // onOpen={() => setSelectOpen(true)}
              label={intl.formatMessage({ id: 'coupon.label.source' })}
              onChange={(e) => handleSelectChange(e)}
              defaultValue='ALL'
              value={source}
            >
              <MenuItem value='ALL'>
                {intl.formatMessage({ id: 'coupon.label.All' })}
              </MenuItem>
              <MenuItem value='internal'>
                {intl.formatMessage({ id: 'coupon.label.Internal' })}
              </MenuItem>
              <MenuItem value='external'>
                {intl.formatMessage({ id: 'coupon.label.External' })}
              </MenuItem>
              <MenuItem value='crm'>
                {intl.formatMessage({ id: 'coupon.label.crm' })}
              </MenuItem>
            </Select>
          </div>
          <KeyboardDatePicker
            required
            margin='normal'
            id='start-date'
            label={intl.formatMessage({ id: 'coupon.label.expirationDate' })}
            format='dd MMM yyyy HH:mm'
            value={expirationDate}
            onChange={handleDateChange('expirationDate')}
            error={expirationDateError !== ''}
            helperText={getFormatedMessage('expirationDateError')}
            disablePast
            fullWidth
            inputVariant='outlined'
          />
          <span className='percentLabel'>
            {intl.formatMessage({ id: 'coupon.label.percent' })}
          </span>
          <div className='div-input-filter'>
            <div
              className='select'
              style={{
                marginBottom: 'initial',
                display: 'flex',
                width: '90%',
                alignItems: 'center',
              }}
            >
              <Select
                value={symbol}
                onChange={(e) => handleSelectChangePercent(e)}
                name='symbol'
                placeholder='%'
              >
                <MenuItem value='%'>
                  <span>%</span>
                </MenuItem>
                {currencies.map((currency: Currency, index: number) => {
                  return (
                    <MenuItem value={currency.currencySymbol} key={index}>
                      <span>{currency.currencySymbol}</span>
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Input
              name='percentReduction'
              value={percentReduction}
              placeholder='20'
              onChange={(e) => handleEventChange(e)}
              className='inputPercent'
            />
          </div>
          <span className='input-text-error'>
            {getFormatedMessage('percentReductionError')}
          </span>
          <InputLabel
            label={intl.formatMessage({ id: 'coupon.label.promoCode' })}
            name='promoCode'
            value={promoCode}
            error={getFormatedMessage('promoCodeError')}
            placeholder='Promo Code'
            onChange={(e) => handleEventChange(e)}
            limit={maxChars.coupon.promocode}
          />
          <InputLabel
            inputType='textArea'
            label={intl.formatMessage({ id: 'coupon.label.description' })}
            name='description'
            value={description}
            error={getFormatedMessage('descriptionError')}
            width={415}
            limit={maxChars.coupon.description || maxChars.description}
            placeholder={intl.formatMessage({
              id: 'coupon.placeholder.description',
            })}
            onChange={(e) => handleEventChange(e)}
          />
        </div>
        {/* <div className='div-content' style={{ marginLeft: '30px' }}></div> */}
        <div
          className='section wireframe-section'
          style={{ marginLeft: 25, marginRight: 25, maxWidth: 356 }}
        >
          <RadioGroup
            name='previews'
            value={switchPreview}
            onChange={handlePreviewChange}
            className='radioGroup'
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel
              value='android'
              control={<Radio />}
              label='Android'
            />
            <FormControlLabel value='iOS' control={<Radio />} label='iOS' />
          </RadioGroup>

          {switchPreview === 'android' && (
            <CouponWireframeAndroid
              backgroundColor={backgroundColor}
              strip={image}
              promotion={percentReduction}
              promoCode={promoCode}
              description={description}
              expires={expirationDate?.toString() || ''}
              name={name}
              symbol={symbol}
              barcodeType={barcodeType}
            />
          )}

          {switchPreview === 'iOS' && (
            <CouponWireframeIPhone
              backgroundColor={backgroundColor}
              image={image}
              expirationDate={new Date(expirationDate).toLocaleDateString(
                'fr-FR',
                {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }
              )}
              promotion={percentReduction}
              promoCode={promoCode}
              description={description}
              titleColor={labelColor.hex}
              textColor={foregroundColor.hex}
              name={name}
              symbol={symbol}
              barcodeType={barcodeType}
            />
          )}
        </div>
      </div>
      {/* <Button onClick={handleClickAddCoupon}>
        {loading ? (
          <CircularProgress size={20} color='inherit' />
        ) : (
          intl.formatMessage({ id: 'coupon.button.add' })
        )}
      </Button> */}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => couponStyles(props)}
`;

const mapStateToProps = ({ coupon, walletForm }: RootState) => {
  const {
    expirationDate,
    labelColor,
    backgroundColor,
    foregroundColor,
    percentReduction,
    promoCode,
    image,
    source,
    description,
    loading,
    symbol,
    name,
    barcodeType,
    expirationDateError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    percentReductionError,
    promoCodeError,
    nameError,
    imageError,
    descriptionError,
  } = coupon;
  return {
    expirationDate,
    labelColor,
    backgroundColor,
    foregroundColor,
    percentReduction,
    promoCode,
    image,
    source,
    description,
    loading,
    symbol,
    name,
    barcodeType,
    currencies: walletForm.currencies,
    expirationDateError,
    labelColorError,
    backgroundColorError,
    foregroundColorError,
    percentReductionError,
    promoCodeError,
    nameError,
    imageError,
    descriptionError,
  };
};

export const connector = connect(mapStateToProps, {
  couponPropsChanged,
  addCoupon,
  couponInitialState,
});

export default connector(Coupon);
