import axios from 'axios';
import { URL } from './config';
import {
  WalletDetailApiResponse,
  CustomerHistoryApiResponse,
  GetWalletApiError,
  WalletInactivesApiResponse,
  SingleWalletApiResponse,
} from './ApiTypes';

export const getWalletListCustomers = (
  walletID: string,
  page: number | string,
  size: number | string,
  search: { firstName: string; lastName: string; email: string } | null = null,
  callback: any
) => {
  const requestURL = `${URL}/wallets/${walletID}/customers`;
  return axios
    .get(requestURL, {
      params: {
        page,
        size,
        firstName: search?.firstName || '',
        lastName: search?.lastName || '',
        email: search?.email || '',
      },
    })
    .then(({ data }: { data: WalletDetailApiResponse }) => {
      callback(null, data);
    })
    .catch((error) => {
      callback(error, null);
    });
};
export const getCustomersStats = (
  walletID: string,

  search: { firstName: string; lastName: string; email: string } | null = null,
  callback: any
) => {
  const requestURL = `${URL}/wallets/${walletID}/customers/stats`;
  return axios
    .get(requestURL, {
      params: {
        firstName: search?.firstName || '',
        lastName: search?.lastName || '',
        email: search?.email || '',
      },
    })
    .then(({ data }: { data: WalletDetailApiResponse }) => {
      callback(null, data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export const getWalletListCustomersInCSV = (
  walletId: string,
  callback: any
) => {
  const requestURL = `${URL}/customers/${walletId}/csv`;
  return axios
    .get(requestURL)
    .then(({ data }: { data: any }) => {
      callback(null, new Blob([data], { type: 'text/csv;charset=utf-8;' }));
    })
    .catch((error) => {
      callback(error, null);
    });
};

export const addCustomersFromCSV = (
  walletId: string,
  fileContent: string,
  sendNotifications: boolean,
  callback: any
) => {
  const requestURL = `${URL}/customers/${walletId}/csv`;

  return axios
    .post(
      requestURL,
      { fileContent, sendNotificationsToCustomers: sendNotifications },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(({ data }: { data: any }) => {
      callback(null, data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export const getCustomerHistoryList = (customerID: string) => {
  const requestURL = `${URL}/customers/${customerID}/history`;
  return axios
    .get(requestURL)
    .then(({ data }: { data: CustomerHistoryApiResponse }) => {
      return data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getWalletById = (walletId: String, callback: any) => {
  const requestURL = `${URL}/wallets/${walletId}`;
  return axios
    .get(requestURL)
    .then(({ data }: { data: SingleWalletApiResponse }) => {
      callback(null, data.response.data);
    })
    .catch((error: GetWalletApiError) => {
      callback(error, null);
    });
};

export const removeCustomer = (id: string) => {
  const requestURL = `${URL}/customers/${id}`;
  return axios
    .delete(requestURL)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateWallet = (idWallet: string, data: any) => {
  const requestURL = `${URL}/wallets/${idWallet}`;
  return axios
    .put(requestURL, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getWalletListInactiveCustomers = (
  walletID: string,
  search: any,
  callback: any
) => {
  const requestURL = `${URL}/customers/${walletID}/inactives?page=${search.page}&size=${search.size}`;
  return axios
    .get(requestURL, {
      params: {
        firstName: search?.firstName || '',
        lastName: search?.lastName || '',
        email: search?.email || '',
        creationDate: search?.creationDate || '',
      },
    })
    .then(({ data }: { data: WalletInactivesApiResponse }) => {
      callback(null, data);
    })
    .catch((error) => {
      callback(error, null);
    });
};

export const sendSelectedRowsInactiveCustomers = (
  walletId: string,
  customersIds: string[],
  creationDate: any,
  allTime: boolean
) => {
  const requestURL = `${URL}/customers/${walletId}/reminders`;
  return axios
    .post(requestURL, {
      customersIds,
      ...(!allTime && creationDate && { creationDate }),
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
