import React from 'react';
import { Wrapper } from './wireframe.style';

import { ellipses } from 'react-icons-kit/iconic/ellipses';
import { ratio } from 'wcag-color';
import barcode from '../../assets/images/barcode.png';
import { CardIOS } from 'react-wallets';

import defaultLogo from '../../assets/images/sm-thumbnail.png';
import cover from '../../assets/images/lg-thumbnail.png';

import Icon from 'react-icons-kit';

import moment from 'moment';

import {
  additionalContacts,
  description,
} from '../walletForm/walletForm.types';
import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';

type WireframeProps = {
  backgroundColor: any;
  name: any;
  logo: any;
  strip: any;
  address: any;
  descriptions: any;
  openingHours: any;
  additionalContacts: any;
  titleColor: any;
  textColor: any;
  facebook: any;
  linkedin: any;
  website: any;
  businessPhone: any;
  servicePhone: any;
  instagram: any;
  gainUnit: string;
  displayWalletName: boolean;
  barcodeType: string;
  label: string;
  value: string;
  labelPersonalizedText: string;
  valuePersonalizedText: string;
  primaryFields: {
    firstField: string;
    secondField: string;
  };
};

type fieldsType = {
  REWARDS: string;
  TYPE: string;
  FULLNAME: string;
  TEXT: string;
  DISABLED: boolean;
};

type headerTypes = {
  fieldName: keyof fieldsType;
  fieldType: string;
  labelPersonnalizedText: string;
  valuePersonnalizedText: string;
  fullName: string;
  gainUnit: string;
  type: string;
};

const WireframeIPhone: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    logo,
    name,
    strip,
    address,
    descriptions,
    openingHours,
    titleColor,
    textColor,
    additionalContacts,
    facebook,
    linkedin,
    website,
    businessPhone,
    servicePhone,
    instagram,
    gainUnit,
    displayWalletName,
    barcodeType,
    label,
    value,
    labelPersonalizedText,
    valuePersonalizedText,
    primaryFields,
  } = props;

  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  const getTopHeaderValue = ({
    fieldName,
    fieldType,
    labelPersonnalizedText,
    valuePersonnalizedText,
    fullName,
    gainUnit,
    type,
  }: headerTypes): string => {
    if (fieldName === 'DISABLED') return '';
    const fields: fieldsType = {
      REWARDS: `0.00${gainUnit}`,
      TYPE: type,
      FULLNAME: fullName,
      TEXT:
        fieldType === 'label' ? labelPersonnalizedText : valuePersonnalizedText,
      DISABLED: false,
    };
    return fields[fieldName];
  };

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
      titleColor={titleColor.hex}
      textColor={textColor.hex}
    >
      <CardIOS
        //card type-----------------------
        type='store'
        //styles----------------------------
        backgroundColor={backgroundColor.hex || ''}
        titleColor={titleColor.hex || ''}
        textColor={textColor.hex || ''}
        //header---------------------------
        headerLogoLink={
          !logo
            ? defaultLogo
            : typeof logo === 'string'
            ? logo
            : URL.createObjectURL(logo)
        }
        title={getTopHeaderValue({
          fieldName: label as keyof fieldsType,
          fieldType: 'label',
          labelPersonnalizedText: labelPersonalizedText,
          valuePersonnalizedText: valuePersonalizedText,
          fullName: `Alan Jason`,
          gainUnit: gainUnit !== 'points' ? gainUnit : '',
          type: 'Silver',
        })}
        mainTitle={getTopHeaderValue({
          fieldName: value as keyof fieldsType,
          fieldType: 'value',
          labelPersonnalizedText: labelPersonalizedText,
          valuePersonnalizedText: valuePersonalizedText,
          fullName: `Alan Jason`,
          gainUnit: gainUnit !== 'points' ? gainUnit : '',
          type: 'Silver',
        })}
        headerTitle={!name ? 'Enseigne' : name}
        bottomLeftTitle='Date'
        bottomLeftValue={moment().format('L')}
        bottomRightTitle={'Gains'}
        bottomRightValue='0.00'
        //title----------------------------
        //infos----------------------------
        topLeftTitle='Date'
        topLeftValue={moment().format('L')}
        topRightTitle={'GAINS'}
        topRightValue='0.00 €'
        //access---------------------------
        // @ts-ignore
        accessType={barcodeType}
        accessCodeId='5g1hdvb82154df853fca14835'
        accessBorder={false}
        //footer--------------------------------
        //@ts-ignore
        bannerImage={
          !strip
            ? cover
            : typeof strip === 'string'
            ? strip
            : URL.createObjectURL(strip)
        }
        //details--------------------------------
        holderNameTitle={'NOM DU CLIENT'}
        holderName={'NOM DU CLIENT'}
        holderLastName={'Alan Jason'}
        holderIdTitle={'MEMBER ID'}
        holderId='5g1hdvb82154df853fca14835'
        kindTitle={'Type'}
        kind='Silver'
        date={moment().format('L HH:mm')}
        addressTitle={'Addresse'}
        address={address}
        customTitle='----'
        customValue='----'
        scheduleTitle={'Horaires'}
        startDate={''}
        schedule={openingHours}
        endDate=''
        offersTitle={'DESCRIPTION OFFRE'}
        offerId="Pas d'offres"
        couponId='Pas de coupons'
        phoneNumberTitle={'Téléphone commercial'}
        phoneNumber={businessPhone}
        phoneNumberSecondaryTitle={'Téléphone S.A.V'}
        phoneNumberSecondary={servicePhone}
        website={website}
        disableNotifications={'Désactiver les notifications'}
      />
    </Wrapper>
  );
};

export default WireframeIPhone;
