import React from 'react';
import { Wrapper } from './wireframe.style';

import { ratio } from 'wcag-color';

import defaultLogo from '../../assets/images/sm-thumbnail.png';
import cover from '../../assets/images/lg-thumbnail.png';

import moment from 'moment';

import CODE128 from '../../assets/images/code128.png';
import PDF417 from '../../assets/images/pdf417.png';
import QR from '../../assets/images/qr.png';
import AZTEC from '../../assets/images/aztec.png';
import { switchBarcode } from '../../utils/common';
import { CardIOS, CardAndroid } from 'react-wallets';
//material
import { Box, Typography } from '@material-ui/core';

type WireframeProps = {
  backgroundColor: any;
  name: any;
  logo: any;
  strip: any;
  address: any;
  descriptions: any;
  openingHours: any;
  additionalContacts: any;
  gainUnit: string;
  barcodeType: string;
  primaryFields: {
    firstField: string;
    secondField: string;
  };
};

const Wireframe: React.FC<WireframeProps> = (props) => {
  const {
    backgroundColor,
    logo,
    name,
    strip,
    address,
    descriptions,
    openingHours,
    additionalContacts,
    gainUnit,
    barcodeType,
    primaryFields,
  } = props;

  const { barcode, barcodeClass } = switchBarcode(barcodeType, {
    CODE128,
    PDF417,
    QR,
    AZTEC,
  });

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      mainContentTextColor={
        ratio('#ffffff', backgroundColor.hex) > 3.0 ? '#ffffff' : '#000000'
      }
    >
      <CardAndroid
        //card type-----------------------
        type='store'
        //styles----------------------------
        backgroundColor={backgroundColor.hex || ''}
        titleColor={'dataWatch.IOSTitleColor' || ''}
        //header---------------------------
        headerLogoLink={
          !logo
            ? defaultLogo
            : typeof logo === 'string'
            ? logo
            : URL.createObjectURL(logo)
        }
        headerTitle={!name ? '"Enseigne"' : name}
        //title----------------------------
        mainTitle={'Carte de fidélité'}
        //infos----------------------------
        topLeftTitle='Date'
        topLeftValue={moment().format('L')}
        topRightTitle={'GAINS'}
        topRightValue='0.00 €'
        //access---------------------------
        // @ts-ignore
        accessType={barcodeType}
        accessCodeId='5g1hdvb82154df853fca14835'
        accessBorder={false}
        //footer--------------------------------
        //@ts-ignore
        bannerImage={
          !strip
            ? cover
            : typeof strip === 'string'
            ? strip
            : URL.createObjectURL(strip)
        }
        //details--------------------------------
        holderNameTitle={'NOM DU CLIENT'}
        holderName={'Alan Jason'}
        holderIdTitle={'MEMBER ID'}
        holderId='5g1hdvb82154df853fca14835'
        kindTitle={'Type'}
        kind='Silver'
        date={moment().format('L HH:mm')}
        addressTitle={'Addresse'}
        address={address}
        customTitle='----'
        customValue='----'
        scheduleTitle={'Horaires'}
        startDate={openingHours}
        endDate=''
        offersTitle={'DESCRIPTION OFFRE'}
        description="Pas d'offres"
        phoneNumberTitle={'Téléphone commercial'}
        phoneNumber={''}
        phoneNumberSecondaryTitle={'Téléphone S.A.V'}
        phoneNumberSecondary={''}
        website={'Site web'}
        disableNotifications={'Désactiver les notifications'}
      />
    </Wrapper>
  );
};

export default Wireframe;
