import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { couponsStyles, CouponsStylesProps } from './coupons.styles';
import Button from '../../commons/Button';

import {
  getAllMoviesTickets,
  couponsSendCoupon,
  couponsInitialState,
  couponsUpdateCouponStatus,
  deleteCinemaTicket,
  editCouponInitialState,
  cinemaTicketsPropsChanged,
} from '../../actions';

import { RootState } from '../../reducers';
//Images
import emptyState from '../../assets/images/emptyState.svg';
import EmptyData from '../../components/EmptyData';
import SimpleDialog from '../../components/Dialog';
import {
  CinemasTicketsApiResponse,
  CouponApiResponse,
} from '../../apis/ApiTypes';
import logo from '../../assets/images/logo-BestWallet.png';
import { getDataFromCache } from '../../utils/cache';
import Text from '../../commons/Text';
import EditCoupon from '../EditCoupon';
import { useHistory } from 'react-router-dom';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Input from '../../commons/Inputs/Input';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { OffersStateError } from '../Offers/offers.types';
import { StyledCheckbox } from '../../commons/CheckBox/index';
import Calendar from '../../icons/Calendar';
import MovieCard from '../../components/Cards/MovieCard';
import CreateCinemaTicket from '../CreateCinemaTicket';
import EventModal from '../../components/Modal';
import { TimePicker } from '@material-ui/pickers';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';

const Coupons: React.FC<any> = (props) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);

  const [selectOpener, setSelectOpener] = React.useState<boolean>(false);
  const history = useHistory();
  const {
    cinemaTicketsList,
    loading,
    loadingSend,
    status,
    allTime,
    startDate,
    search,
    endDate,
    endDateError,
    offersFilterList,
  } = props;

  const [searchFilter, setSearchFilter] = React.useState<any[]>([
    { value: '', name: 'movieName' },
    { value: '', name: 'openingHour' },
  ]);
  const [time, setTime] = React.useState<any>();

  const objectUser: any = getDataFromCache('user');

  React.useEffect(() => {
    props.getAllMoviesTickets(objectUser.wallet, searchFilter);
  }, []);

  const handleClickDeleteCinema = (cinemaTicketId: string) => {
    props.deleteCinemaTicket(cinemaTicketId, objectUser.wallet);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {};

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.cinemaTicketsPropsChanged(event.target.name, event.target.checked);
  };
  const handleDateChange = (prop: string) => (date: any) => {
    if (prop === 'time') {
      setTime(date);
      searchFilter[1].value = new Date(date);
    }

    setSearchFilter(searchFilter);
  };
  const HandleClickSearch = () => {
    props.getAllMoviesTickets(objectUser.wallet, searchFilter);
  };
  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'search') {
      if (event.target.value.length > 60) return;
      searchFilter[0].value = event.target.value;

      setSearchFilter(searchFilter);
    }
  };
  React.useEffect(() => {
    return () => {
      props.couponsInitialState();
    };
  }, []);

  return (
    <Wrapper
      loading={loading.toString()}
      couponsLength={cinemaTicketsList.length}
    >
      <div className='div-search'>
        <div className='div-search-inputs'>
          <div className='div-input-filter'>
            <FormControl className='filter-container' fullWidth>
              <Input value={'Nom Film'} type='text' disabled />
              <div
                className='filter-button'
                onClick={() => setSelectOpener(!selectOpener)}
              >
                <h3>
                  {intl.formatMessage({
                    id: 'wallet.label.filter',
                  })}
                </h3>
                <ExpandMoreRoundedIcon
                  style={{ color: 'white' }}
                  className={selectOpener ? 'filter-opened' : ''}
                />
              </div>
              <Select
                className='select-input '
                value={status}
                onChange={handleSelectChange}
                placeholder={intl.formatMessage({
                  id: 'wallet.label.filter',
                })}
                id='filter'
                inputProps={{ className: 'input' }}
                open={selectOpener}
                onClose={() => setSelectOpener(false)}
              >
                <MenuItem
                  value='movieName'
                  onMouseEnter={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#ff5078')
                  }
                  onMouseLeave={(e) =>
                    ((e.target as HTMLInputElement).style.color = '#000000')
                  }
                >
                  <span>{'Nom Film'}</span>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='div-input-search'>
            <Input
              value={search}
              background='#fff'
              type='text'
              name='search'
              placeholder={intl.formatMessage({
                id: 'wallet.button.rechercher',
              })}
              // width={690}
              margin='10px 0'
              className='input-search'
              onChange={handleEventChange}
            />
            <Button
              onClick={HandleClickSearch}
              width={120}
              background='#FF5078'
              className='button-search'
            >
              {intl.formatMessage({ id: 'wallet.button.rechercher' })}
            </Button>
          </div>
        </div>
      </div>
      <div className='div-date'>
        {!allTime ? (
          <div className='div-search-date'>
            <KeyboardDateTimePicker
              required
              label={'Date'}
              format='MMM dd yyyy HH:mm'
              ampm={false}
              value={time}
              onChange={handleDateChange('time')}
              disablePast
              inputVariant='outlined'
              style={{ width: '215px', marginRight: '12px' }}
              TextFieldComponent={({ InputProps, ...rest }) => (
                <TextField
                  {...rest}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Divider
                          className='divider'
                          orientation='vertical'
                          variant='inset'
                          light
                        />
                        <IconButton
                          onClick={
                            (InputProps as any)?.endAdornment.props.children
                              .props.onClick
                          }
                        >
                          <Calendar />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        ) : (
          <div className='div-search-date'></div>
        )}

        <FormControlLabel
          control={
            <StyledCheckbox
              checked={allTime}
              onChange={handleCheckboxChange}
              name='allTime'
            />
          }
          label={intl.formatMessage({ id: 'ships.label.allTime' })}
        />
      </div>
      <div className='div-header'>
        <Text color='#404040' fontSize={24} lineHeight={36} fontWeight={600}>
          {intl.formatMessage({ id: 'cinemaTickets.label.title' })}
        </Text>
        <Button onClick={() => history.push('cinemaTickets/create')}>
          {intl.formatMessage({ id: 'cinemaTickets.button.create' })}
        </Button>
      </div>
      <EventModal height={700} open={open} onClose={() => setOpen(!open)}>
        <CreateCinemaTicket
          walletId={objectUser.wallet}
          onSaveChanged={() => setOpen(!open)}
        ></CreateCinemaTicket>
      </EventModal>
      <div className='div-list'>
        <EmptyData
          data={cinemaTicketsList}
          loading={loading}
          message={intl.formatMessage({ id: 'cinemaTickets.label.emptyList' })}
          image={emptyState}
        >
          {cinemaTicketsList.map((cinemaTicket: CinemasTicketsApiResponse) => (
            <MovieCard
              key={cinemaTicket._id}
              onClickDelete={handleClickDeleteCinema}
              cinemaTicket={cinemaTicket}
              onClickRedirect={() =>
                history.push('/cinemaTickets/' + cinemaTicket._id)
              }
            ></MovieCard>
          ))}
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')<CouponsStylesProps>`
  ${(props) => couponsStyles(props)}
`;

const mapStateToProps = ({ cinemaTickets }: RootState) => {
  const { cinemaTicketsList, loading, loadingSend } = cinemaTickets;

  return {
    cinemaTicketsList,
    loading,
    loadingSend,
  };
};

export const connector = connect(mapStateToProps, {
  getAllMoviesTickets,
  couponsInitialState,
  couponsUpdateCouponStatus,
  editCouponInitialState,
  cinemaTicketsPropsChanged,
  deleteCinemaTicket,
});

export default connector(Coupons);
