import React, { useEffect, useState } from 'react';
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

interface Data {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  shortLink: string;
  createdAt: string;
}

function createData(
  _id: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  shortLink: string,
  createdAt: string
): Data {
  return { _id, firstName, lastName, email, phoneNumber, shortLink, createdAt };
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'Nom',
  },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Prenom' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  {
    id: 'phoneNumber',
    numeric: false,
    disablePadding: false,
    label: 'Télephone',
  },

  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date de creation',
  },
];

interface EnhancedTableProps {
  classes: any;
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '&:nth-of-type(odd)': {
        backgroundColor: 'white',
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'grey',
      },
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    circularProgressStyle: {
      color: '#ff5078',
    },
    tableBody: {
      height: '500px',
      width: '934px',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

type CollapsibleTableProps = {
  data: Array<any>;
  handleClickRow: (name: string, selectedIds: string[]) => void;
  loading?: boolean;
  total: number;
  size: number;
  // page: number;
  // rowsPerPage: number;
};
const EnhancedTable: React.FC<CollapsibleTableProps> = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { data, handleClickRow, loading, total, size } = props;

  const [rows, setRows] = useState<Data[]>([]);
  useEffect(() => {
    if (data) {
      setSelected([]);
      handleClickRow('selectedId', []);

      let Arr: Data[] = [];
      data?.forEach((row: Data) => {
        Arr.push(
          createData(
            row._id,
            row.firstName,
            row.lastName,
            row.email,
            row.phoneNumber,
            row.shortLink,
            row.createdAt
          )
        );
      });

      setRows(Arr);
    }
  }, [data]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      handleClickRow('selectedId', newSelecteds);
      return;
    }
    setSelected([]);
    handleClickRow('selectedId', []);
  };

  const handleClick = (event: React.MouseEvent<unknown>, _id: string) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    handleClickRow('selectedId', newSelected);
    setSelected(newSelected);
  };

  const isSelected = (_id: string) => selected.indexOf(_id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <>
      {!loading ? (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby='tableTitle'
                size={dense ? 'small' : 'medium'}
                aria-label='enhanced table'
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row._id)}
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell align='left'>{row.firstName}</TableCell>
                        <TableCell align='left'>{row.lastName}</TableCell>
                        <TableCell align='left'>{row.email}</TableCell>
                        <TableCell align='left'>{row.phoneNumber}</TableCell>
                        <TableCell align='left'>
                          {moment(row.createdAt).format('MMMM DD YYYY')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      ) : (
        <div className={classes.tableBody}>
          <CircularProgress></CircularProgress>
        </div>
      )}
    </>
  );
};
export default EnhancedTable;
